import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, RouterOutlet } from '@angular/router';

import { NavbarService, SafariRootAppComponent } from '@safarilaw-webapp/shared/app-bootstrap';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';

import { NgClass } from '@angular/common';
import { RmsHeaderComponent, RmsNavbarComponent } from '@safarilaw-webapp/feature/rms/shared';
import { BlockUIModule } from 'ng-block-ui';
import { AppUpdateSplashComponent } from './app-update-splash/components/app-update-splash/app-update-splash';
import { NavbarItemCollection } from './navbar-item-collection';
import { MaintenanceHeaderComponent } from './site-maintenance/components/maintenance-header/maintenance-header';
import { MaintenanceSplashComponent } from './site-maintenance/components/maintenance-splash/maintenance-splash';

@Component({
  standalone: true,
  selector: 'sl-rms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [MaintenanceSplashComponent, AppUpdateSplashComponent, MaintenanceHeaderComponent, RouterOutlet, NgClass, RmsHeaderComponent, RmsNavbarComponent, BlockUIModule]
})
export class AppComponent extends SafariRootAppComponent implements OnInit, OnDestroy {
  showSplash = false;

  constructor(
    injector: Injector,

    navbarService: NavbarService,
    private _featureFlagService: FeatureFlagsService,

    private _reduxWrapperService: ReduxWrapperService
  ) {
    super(injector, navbarService);

    this._navbarItems = [...NavbarItemCollection];
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  OnNavigationEnd(evt: NavigationEnd) {
    super.OnNavigationEnd(evt);
  }

  get canShowHeader() {
    return this.showHeader;
  }
  get canShowNavbar() {
    return this.showNavbar;
  }

  get showDivider(): boolean {
    return this._router.url?.toLowerCase() != '/profile';
  }
}
