 
        <ng-container *slUiKitSharedObservables="{
            navbarActive: navbarActive$ | async,
            companyLogoUrl: this.companyLogoUrl$ | async,
            companyLabel: this.companyLabel$ | async,
            useCompanyLabel: this.useCompanyLabel$ | async,
            useCompanyLogo: this.useCompanyLogo$ | async
            } as sharedObservables; notRequired: ['navbarActive', 'companyLogoUrl', 'companyLabel', 'useCompanyLabel', 'useCompanyLogo']">    

    <header class="navbar">
        <div class="container s_con">
            <!-- ### Use s_container-wide for tables / remove for forms -->
            <div class="mr-auto" id="s_navleft">

            <div class="float-left">
                <button type="button"
                    class="navbar-toggler" [class.collapsed]="!sharedObservables.navbarActive"
                    (click)="toggleSidebar()"
                >
                    <span> </span>
                    <span> </span>
                    <span> </span>
                </button>
            </div>     
       
                <!-- TITLE-->
                <div class="s_con-title float-left">
                   
                   <div id="s_header-co-logo" class="s_header-co-logo">
                    @if(sharedObservables.useCompanyLogo && sharedObservables.companyLogoUrl) {
                        <!-- If we are  specifying to use company logo AND  there is  company logo available then show the logo -->
                        <img [src]="sharedObservables.companyLogoUrl" alt="Company Logo" style="max-height: 50px; margin-right: 20px;">
                    } @else if (!sharedObservables.useCompanyLabel || !sharedObservables.companyLabel) {
                        <!-- If we are not specifying to use company label OR if there is no company label then show the title -->
                        <span >{{ title }}</span>
                    } @else if (sharedObservables.useCompanyLabel && sharedObservables.companyLabel) {
                        <!-- If we are  specifying to use company label AND   there is  company label then show the company label -->
                        <span  style="display: inline-block; vertical-align: middle;">{{sharedObservables.companyLabel}}</span>
                    }
                 
                 
                  
                   </div>    
                </div><!-- END: s_con-title-->
                <!-- END: #s_view -->
               
            </div><!-- END: #s_navleft -->
            <div id="s_navright"> 
                <!-- <div  class="s_con-title float-left">
                  <ng-template #viewContainerRef></ng-template>
              </div> -->
              <!-- HELP -->
              <div id="s_help" class="dropdown float-left" dropdown>
                  <a class="dropdown-toggle"   role="button" id="s_hlp-link"  dropdownToggle>
                      <span class="s_encircle"></span>
                  </a>
                  <ul id="s_hlp-content" class="dropdown-menu dropdown-menu-right s_account"  *dropdownMenu >
                    <li role="menuitem"><div class="s_dropdown-header">ServePort Support</div></li>
                        <li role="menuitem"><a [routerLink]="['/support/about']" class="dropdown-item">About ServePort&trade;</a></li>
                            <li role="menuitem"><a [routerLink]="['/support/getting-started']" class="dropdown-item">Getting Started / Instructions</a></li>
                                <li role="menuitem"> <a [routerLink]="['/support']" class="dropdown-item">Support</a></li>
                  </ul><!-- END: s_hlp -->
              </div>
              @if(currentUserInfo$ | async; as headerModel) {
                <div id="s_account" class="dropdown float-left" dropdown >
                    <a class="dropdown-toggle"  role="button" id="s_account-link" dropdownToggle >
                        <span class="s_encircle">{{headerModel.initials}}</span>
                    </a>
                    
                    <ul id="s_account-content" class="dropdown-menu dropdown-menu-right s_account" *dropdownMenu >
                        
                        @if(isAuthenticated()) {
                            <li role="menuitem"> 
                            <div  
                            class ="pr-3 text-break whitespace-normal s_dropdown-header" 
                            >
                            @if(headerModel.name) {
                                <span >Hello, {{ headerModel.name }}</span>
                                <br>
                                <span id="s_account-email">{{headerModel.email}}</span>
                            } @else {
                                <span >Hello, <span id="s_account-email">{{ headerModel.email }}</span></span>
                            }
                           
                            
                        </div>
                            </li>
                            <li role="menuitem"> 
                        <a  id="s_account-terms" 
                              class="dropdown-item"
                              
                              [routerLink]="['/profile']">
                              My Profile
                          </a>
                            </li>
                            <li role="menuitem"> 
                        <a  id="s_account-terms" 
                            class="dropdown-item"
                            
                            [routerLink]="['/legal']">
                            Terms &amp; Privacy
                        </a>
                            </li>
                            <li role="menuitem"> 
                        <a id="s_account-logout" 
                            [routerLink]="['/logout']"
                            class="dropdown-item" 
                            
                          >
                            Sign Out
                        </a>
                            </li>
                        }
                      
                    </ul>
                </div>
              }
 

              </div>
         
        </div>
       
    </header>
        </ng-container>
 