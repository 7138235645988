// this seems to be a bug in lint parser. It thinks we are importing the whole library and throws 'Static imports of lazy-loaded libraries are forbidden' error.
// But we're not importing the whole library, just the definittion of the base PageNotFoundComponent, which is a standalone index file.
/* eslint-disable @nx/enforce-module-boundaries  -- comments above */
/* eslint-disable no-restricted-syntax -- global inject OK in main.ts */
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ENVIRONMENT_INITIALIZER, ErrorHandler, importProvidersFrom, inject, InjectionToken } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideRouter, Router, RouteReuseStrategy, withDisabledInitialNavigation, withInMemoryScrolling, withPreloading, withRouterConfig } from '@angular/router';

import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { FeatureRmsSharedModule, RmsAuthService, RmsNavbarService, RmsUserVerificationService } from '@safarilaw-webapp/feature/rms/shared';
import { AppCustomPreloader, NavbarPermissionsService, NavbarService } from '@safarilaw-webapp/shared/app-bootstrap';
import { AuthInterceptorService, AuthService, SharedAuthModule, UserVerificationService } from '@safarilaw-webapp/shared/auth';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FeatureRmsDataAccessModule } from '@safarilaw-webapp/feature/rms/data-access';

import { ModalDialogService } from '@safarilaw-webapp/shared/dialog';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { CHUNK_RETRY_COUNT_PARAM, ErrorDialogComponent, ErrorHandlerService, HttpErrorInterceptorService } from '@safarilaw-webapp/shared/error-handling';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { SafariDefaultReuseStategy } from '@safarilaw-webapp/shared/routing-utility';
import { SharedUiKitComponentStateModule } from '@safarilaw-webapp/shared/ui-kit/component-state';
import { BlockUIModule } from 'ng-block-ui';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Observable, tap } from 'rxjs';
import { AppComponent } from './app/app.component';
import { environmentImports } from './app/app.module.env.imports';
import { routes } from './app/app.routing';
import { RmsConfigurationService } from './app/configuration/rms-configuration.service';
import { RmsNavbarPermissionsService } from './app/navbar/services/lpms-navbar-permissions/rms-navbar-permissions.service';

import { GATEWAY_TIMEOUT_URLS } from '@safarilaw-webapp/common-pages/gateway-timeout';
import { SUPPORT_EMAIL } from '@safarilaw-webapp/common-pages/page-error';
import { IRmsUiState, RmsUiReducerService } from '@safarilaw-webapp/feature/rms/ui/redux';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IRmsUiState>>('Rms Ui Reducers');

const initialAppConfigFactory =
  (appConfigService: AppConfigurationService, loggerService: LoggerService): (() => Observable<boolean>) =>
  (): Observable<boolean> =>
    appConfigService.overrideConfiguration().pipe(tap(() => loggerService.initializeElmahLogging()));

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload'
      }),
      withPreloading(AppCustomPreloader),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top'
      }),
      withDisabledInitialNavigation()
    ),
    importProvidersFrom(
      BlockUIModule.forRoot(),
      BrowserAnimationsModule,
      BsDropdownModule.forRoot(),
      CollapseModule.forRoot(),
      FeatureRmsSharedModule,
      FeatureRmsDataAccessModule,
      BrowserModule,
      SharedAuthModule,
      SharedUiKitComponentStateModule,
      StoreModule.forFeature('rms', REDUCER_TOKEN),

      [...environmentImports]
    ),
    provideHttpClient(),
    RmsUiReducerService,

    {
      provide: REDUCER_TOKEN,
      deps: [RmsUiReducerService],
      useFactory: (service: RmsUiReducerService) => service.reducers
    },
    {
      provide: GATEWAY_TIMEOUT_URLS,
      useValue: 'serveport.com / *.serveport.com'
    },
    {
      provide: SUPPORT_EMAIL,
      useValue: 'support@serveport.com'
    },

    { provide: UserVerificationService, useClass: RmsUserVerificationService },
    { provide: AuthService, useClass: RmsAuthService },
    { provide: NavbarService, useClass: RmsNavbarService },
    { provide: APP_INITIALIZER, useFactory: initialAppConfigFactory, deps: [AppConfigurationService, LoggerService], multi: true },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => {
        inject(ModalDialogService).registerComponent(ErrorDialogComponent.ClassId, ErrorDialogComponent);

        const router = inject(Router);

        const url = new URL(window.location.href);

        // Don't blindly navigate to init. In normal circumstances the module should never load with
        // "init" as its startup path, but it could if this happened during chunkload error. So if that's
        // the case make sure you pass the chunk count
        if (url.pathname.toLowerCase() == '/init') {
          const chunkCount = parseInt(url.searchParams.get(CHUNK_RETRY_COUNT_PARAM), 10);
          if (!isNaN(chunkCount)) {
            void router.navigate(['init'], {
              queryParams: {
                // eslint-disable-next-line @typescript-eslint/naming-convention -- used seldomly, so I m just skipping eslint here, instead of config
                __chcn: chunkCount
              }
            });
            return;
          }
        }
        // Otherwise this is a normal flow so go to init component but don't change the location
        void router.navigate(['/init'], { skipLocationChange: true });
      }
    },
    {
      provide: AppConfigurationService,
      useClass: RmsConfigurationService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: NavbarPermissionsService,
      useClass: RmsNavbarPermissionsService
    },
    {
      provide: RouteReuseStrategy,
      useClass: SafariDefaultReuseStategy
    }
  ]
}).catch(err => {
  throw err;
});
