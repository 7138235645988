@if (shouldShowSwWarning) {
  <h4 class="text-danger text-center">
    Warning. It looks like your service worker may be disabled. Did you use CTRL+F5 or have bypass for url checked ?
  </h4>
}
<block-ui >

  @if (!siteUnderMaintenance && !showSplash) {
    @if (shouldShowBanner()) {
      <div class="alert alert-warning alert-dismissible fade show text-center mb-0" role="alert">
        <strong>Unsupported browser detected!</strong> To ensure the full functionality of ServePort, please use a current version of <a href="https://www.google.com/chrome/">Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/">FireFox</a>, or <a href="https://www.microsoft.com/en-us/edge">Edge</a>.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    }
    <div class="mainClass">
      <div class="wrapper" >
        @if (canShowNavbar) {
          <sl-bootstrap-navbar></sl-bootstrap-navbar>
        }
        <main>
          @if (siteScheduledMaintenance) {
            <sl-rms-maintenance-header [maintenanceInfo]="maintenanceInfo"></sl-rms-maintenance-header>
          }
          @if (canShowHeader) {
            <sl-bootstrap-header></sl-bootstrap-header>
          }
          <div [ngClass]="containerClass">
            @if (showDivider) {
              <aside id="s_act-primary" class="s_act p-0" style="width: 100%;border-bottom: none;">
                <div class="row no-gutters">
                  <div  class="col"></div>
                </div>
              </aside>
            }
            <router-outlet ></router-outlet>
          </div>
        </main>
      </div>
    </div>
  }

  @if (siteUnderMaintenance && !showSplash) {
    <sl-rms-maintenance-splash [maintenanceInfo]="maintenanceInfo"></sl-rms-maintenance-splash>
  }

  @if ( showSplash) {
    <sl-rms-update-splash></sl-rms-update-splash>
  }

</block-ui>