import { Injectable } from '@angular/core';
import { SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { PageUiAction, PageUiState, SafariReduxPageUiObject, defaultPageUiState } from '@safarilaw-webapp/shared/redux';
import * as redux from '../../redux/index';

export class IRmsPortalPageUiState extends PageUiState {
  deliveryPortalReloadRequest: PageUiAction;
  confirmReceiptRequest: PageUiAction;
  portalAccessRequest: PageUiAction;
  acknowledgmentRequest: PageUiAction;
  portalFileDownloadRequest: { id: SafariObjectId };
  documentListDownloadRequest: PageUiAction;
  documentZipDownloadRequest: PageUiAction;
}

const defaultPortalPageUiState = {
  deliveryPortalReloadRequest: undefined,
  portalAccessRequest: undefined,
  acknowledgmentRequest: undefined,
  portalFileDownloadRequest: undefined,
  documentListDownloadRequest: undefined,
  documentZipDownloadRequest: undefined,
  confirmReceiptRequest: undefined,
  ...defaultPageUiState
};
@Injectable({ providedIn: 'root' })
export class RmsPortalPageUiReduxObject extends SafariReduxPageUiObject<IRmsPortalPageUiState> {
  // Doing this to prevent the compiler from complaining. We will be getting rid of this default
  // thing but this makes it compile for now
  default = {};

  requestDeliveryPortalReload = super.addMessage('Request Delivery Portal Reload', state => state.deliveryPortalReloadRequest, { deliveryPortalReloadRequest: this.fromAction() });
  requestConfirmReceipt = super.addMessage('Request Confirm Receipt', state => state.confirmReceiptRequest, { confirmReceiptRequest: this.fromAction() });
  requestPortalAccess = super.addMessage('Request Portal Access', state => state.portalAccessRequest, { portalAccessRequest: this.fromAction() });
  requestAcknowledgment = super.addMessage('Request Acknowledgment', state => state.acknowledgmentRequest, { acknowledgmentRequest: this.fromAction() });
  requestDocumentListDownload = super.addMessage('Request Document List Download', state => state.documentListDownloadRequest, { documentListDownloadRequest: this.fromAction() });
  requestDocumentZipDownload = super.addMessage('Request Document Zip Download', state => state.documentZipDownloadRequest, { documentZipDownloadRequest: this.fromAction() });

  constructor() {
    const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.portalPageUiState);

    super('Rms', 'Portal', getPageState, defaultPortalPageUiState);
  }
}
